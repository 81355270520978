import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6335a244"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "flex-none bg-gray-300 w-24 max-w-24 h-16" }
const _hoisted_3 = { class: "flex-1 min-w-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    class: "m-1 mb-2",
    button: true,
    "router-link": _ctx.href,
    "router-direction": "forward"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ion_img, { src: _ctx.imageSrc }, null, 8, ["src"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ion_card_header, { class: "p-2 truncate" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_title, {
                class: "text-base truncate",
                dir: _ctx.rightToLeft ? 'rtl' : 'ltr'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.chapterName), 1)
                ]),
                _: 1
              }, 8, ["dir"])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["router-link"]))
}