
import { IonCard, IonCardHeader, IonCardTitle, IonImg } from '@ionic/vue'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: ['imageSrc', 'chapterName', 'href', 'rightToLeft'],
  components: { IonCard, IonCardHeader, IonCardTitle, IonImg },
  setup() {
    const router = useRouter()
    return { router }
  }
})
