import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChapterTile = _resolveComponent("ChapterTile")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.chapters, (chapter) => {
            return (_openBlock(), _createBlock(_component_ChapterTile, {
              key: chapter.id,
              chapterName: chapter.translations[$setup.settings.primaryLanguage],
              rightToLeft: $setup.rightToLeftLanguages.includes($setup.settings.primaryLanguage),
              href: chapter.href || `/kapitel/${chapter.id}`,
              imageSrc: chapter.imageSrc
            }, null, 8, ["chapterName", "rightToLeft", "href", "imageSrc"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}