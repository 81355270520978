
import { IonPage, IonContent } from '@ionic/vue'
import ChapterTile from '../components/ChapterTile.vue'
import { useRouter } from 'vue-router'
import { getChapters, getRightToLeftLanguages } from '../db'
import { settings } from '../settings'

export default {
  name: 'Chapters',
  components: { IonPage, IonContent, ChapterTile },
  setup() {
    const router = useRouter()

    return {
      router,
      settings,
      rightToLeftLanguages: getRightToLeftLanguages(),
      chapters: getChapters()
    }
  }
}
